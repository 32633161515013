export enum PermissionConsts {
    Pages_Tenants = 'Pages.Tenants',
    Pages_Users = 'Pages.Users',
    Pages_Parents = 'Pages.Parents',
    Pages_MediaEvents = 'Pages.MediaEvents',

    Pages_Roles = 'Pages.Roles',
    Pages_Contracts = 'Pages.Contracts',
    Pages_Customers = 'Pages.Customer',
    Pages_Services = 'Pages.Services',
    Pages_Files = 'Pages.Files',
    Pages_OrganizationUnits = 'Pages.OrganizationUnits',
    Pages_HRRequests = 'Pages.HRRequests',
    Pages_Commitments = 'Pages.Commitments',
    Pages_Clockings = 'Pages.Clockings',
    Pages_ScheduledEvents = 'Pages.ScheduledEvents',
    Pages_Plannings = 'Pages.Plannings',

    User_Detail = 'User.Detail',
    User_Create = 'User.Create',
    User_Edit = 'User.Edit',
    User_Delete = 'User.Delete',
    User_ChangePassword = 'User.ChangePassword',
    User_ForceResetPassword = 'User.ForceResetPassword',
    User_Deactivate = 'User.Deactivate',
    User_RequestEmailConfirmation = 'User.RequestEmailConfirmation',
    User_ServiceAssociation = 'User.ServiceAssociation',
    User_UnlockLogin = 'User.UnlockLogin',
    User_ManageCertification = 'User.ManageCertification',
    User_ManageQualification = 'User.ManageQualification',
    User_ManageProfessionalRegister = 'User.ManageProfessionalRegister',
    User_ManageEducationalDegree = 'User.ManageEducationalDegree',
    User_IsAssignableInServiceOperator = 'User.IsAssignableInServiceOperator',
    User_IsAssignableInServiceBeneficiary = 'User.IsAssignableInServiceBeneficiary',
    User_HasCertification = 'User.HasCertification',
    User_HasProfessionalRegister = 'User.HasProfessionalRegister',
    User_HasQualification = 'User.HasQualification',
    User_HasEducationalDegree = 'User.HasEducationalDegree',

    Role_Manage = 'Role.Manage',
    Role_Create = 'Role.Create',
    Role_Edit = 'Role.Edit',
    Role_Delete = 'Role.Delete',
    Role_GetAll = 'Role.GetAll',

    Customer_Create = 'Customer.Create',
    Customer_Edit = 'Customer.Edit',
    Customer_Delete = 'Customer.Delete',

    Contract_Create = 'Contract.Create',
    Contract_Edit = 'Contract.Edit',
    Contract_Delete = 'Contract.Delete',

    Contract_Manage = 'Contract.Manage',

    Service_Create = 'Service.Create',
    Service_Delete = 'Service.Delete',
    Service_Edit = 'Service.Edit',
    Service_TrackTask = 'Service.TrackTasks',
    Service_ManageAssociation = 'Service.ManageAssociations',
    Service_ManageChoosedServiceAsCoordinator = 'Service.ManageChoosedServiceAsCoordinator',

    ServiceOperatorScheduled_Manage = 'ServiceOperatorScheduled.Manage',
    ServiceOperatorScheduled_Create = 'ServiceOperatorScheduled.Create',
    ServiceOperatorScheduled_Delete = 'ServiceOperatorScheduled.Delete',
    ServiceOperatorScheduled_Edit = 'ServiceOperatorScheduled.Edit',

    File_Associate_Service = 'File.Associate.Service',
    File_Associate_Contract = 'File.Associate.Contract',
    File_Associate_User = 'File.Associate.User',
    File_Associate_HRRequest = 'File.Associate.HRRequest',
    File_Associate_Activity = 'File.Associate.Activity',
    File_Associate_Commitment = 'File.Associate.Commitment',

    File_Associate = 'File.Associate',
    File_Create = 'File.Create',
    File_Delete = 'File.Delete',
    File_Edit = 'File.Edit',

    ScheduledNotification_Manage = 'ScheduledNotification.Manage',
    ScheduledNotification_Edit = 'ScheduledNotification.Edit',
    ScheduledNotification_Delete = 'ScheduledNotification.Delete',
    ScheduledNotification_Create = 'ScheduledNotification.Create',

    OrganizationUnit_Manage = 'OrganizationUnit.Manage',
    OrganizationUnit_Create = 'OrganizationUnit.Create',
    OrganizationUnit_Delete = 'OrganizationUnit.Delete',
    OrganizationUnit_Edit = 'OrganizationUnit.Edit',

    HRRequest_Create = 'HRRequest.Create',
    HRRequest_Delete = 'HRRequest.Delete',
    HRRequest_EditState = 'HRRequest.EditState',
    HRRequest_Edit = 'HRRequest.Edit',
    HRRequest_ManageApprovationFlow = 'HRRequest.ManageApprovationFlow',

    Topic_Create = 'Topic.Create',

    ServiceReport_Create = 'ServiceReport.Create',
    ServiceReport_Edit = 'ServiceReport.Edit',
    ServiceReport_Delete = 'ServiceReport.Delete',

    PaymentManagement_GetAll = 'PaymentManagement.GetAll',
    PaymentManagement_Import = 'PaymentManagement.Import',
    PaymentManagement_Manage = 'PaymentManagement.Manage',
    PaymentManagement_Edit = 'PaymentManagement.Edit',

    Activity_Manage = 'Activity.Manage',
    Activity_Create = 'Activity.Create',
    Activity_Edit = 'Activity.Edit',
    Activity_Delete = 'Activity.Delete',

    Commitment_Manage = 'Commitment.Manage',
    Commitment_Create = 'Commitment.Create',
    Commitment_Edit = 'Commitment.Edit',
    Commitment_Delete = 'Commitment.Delete',

    Receipts_Manage = 'Receipt.Manage',
    Receipts_Create = 'Receipt.Create',
    Receipts_Edit = 'Receipt.Edit',
    Receipts_Delete = 'Receipt.Delete',

    SchedulerMedia_Delete = 'SchedulerMedia.Delete',

    // Pianificazione
    Plannings_Create = 'Plannings.Create',
    Plannings_Export = 'Plannings.Export',
    Plannings_Edit = 'Plannings.Edit',
    Plannings_Manage = 'Plannings.Manage',
    Plannings_Delete = 'Plannings.Manage',

    // Scadenzario
    ScheduledEvents_Create = 'ScheduledEvents.Create',
    ScheduledEvents_Edit = 'ScheduledEvents.Edit',
    ScheduledEvents_GetAll = 'ScheduledEvents.GetAll',
    ScheduledEvents_Manage = 'ScheduledEvents.Manage',

    Clockings_Create = 'Clockings.Create',
    Clockings_Edit = 'Clockings.Edit',
    Clockings_Delete = 'Clockings.Delete',
    Clockings_GetAll = 'Clockings.GetAll',
    Clockings_Export = 'Clockings.Export',
    Clockings_Manage = 'Clockings.Manage',

    Educators_List = 'Educators.List',

    Attendances_Create = 'Attendances.Create',
    Attendances_Edit = 'Attendances.Edit',
    Attendances_GetAll = 'Attendances.GetAll',

    ActivityCategories_Create = 'ActivityCategories.Create',
    ActivityCategories_Edit = 'ActivityCategories.Edit',
    ActivityCategories_Delete = 'ActivityCategories.Delete',
    ActivityCategories_GetAll = 'ActivityCategories.GetAll',

    Parents_Edit = 'Parents.Edit',
    Parents_Create = 'Parents.Create',
    Parents_Delete = 'Parents.Delete',
    Parents_Get = 'Parents.Get',
    Parents_GetAll = 'Parents.GetAll',
    Parents_Manage = 'Parents.Manage',

    MediaEvents_GetAll = 'MediaEvents.GetAll',
    MediaEvents_Get = 'MediaEvents.Get',
    MediaEvents_Create = 'MediaEvents.Create',
    MediaEvents_Edit = 'MediaEvents.Create',
    MediaEvents_Delete = 'MediaEvents.Delete',
}
